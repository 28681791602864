<template>
    <div class="accComplete">
        <div class="pageCenter form-container">
            <el-form :model="regiForm" status-icon :rules="regiFormRules" ref="regiForm" label-width="100px">
                <el-form-item>
                    <strong><h1>设置登录的账号和密码</h1></strong>
                </el-form-item>
                <el-form-item label="账号" prop="username">
                    <el-input v-model="regiForm.username" autocomplete="off" placeholder="请输入您的用户名称"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="regiForm.password" autocomplete="off" placeholder="请输入您的登录密码"
                              show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password_confirm">
                    <el-input v-model="regiForm.password_confirm" autocomplete="off" placeholder="请再次输入您的密码"
                              show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="regiForm.agreement">同意
                        <el-link :underline="false" style="color:#0366C3;" href="" type="primary">《乐搜家平台服务条款》
                        </el-link>
                        及
                        <el-link :underline="false" style="color:#0366C3;" href="" type="primary">《隐私协议》</el-link>
                    </el-checkbox>
                </el-form-item>
                <el-form-item label-width="100px">
                    <el-button :disabled="!regiForm.agreement" @click="submitRegister" type="primary">保存设置</el-button>
                    <el-button @click="cancelRegister" type="default">暂不设置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import oucy from "../../util/oucyUtil";
    import {userMaterial} from "@/service";
    export default {
        name: "AccComplete",
        data(){
            const me = this;
            const validateUsername = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入用户名称'));
                } else if (value.length < 2 || value.length > 15) {
                    callback(new Error('长度在 3 到 15 个字符'));
                } else {
                    oucy.postRequest('/client/user/userauth/vertifyRegisterUsername', {
                        username: value
                    }).then(res => {
                        callback();
                    }).catch(err => {
                        callback(new Error('该用户名已被注册'));
                    })
                }
            };
            const validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入密码'));
                } else if (value.length < 6 || value.length > 25) {
                    callback(new Error('长度在 6 到 25 个字符'));
                } else {
                    if (this.regiForm.password_confirm !== '') {
                        this.$refs.regiForm.validateField('password_confirm');
                    }
                    callback();
                }
            };
            const validatePass_confirm = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.regiForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return{
                regiForm:{
                    username:null,
                    password:null,
                    password_confirm:null,
                    agreement:null
                },
                regiFormRules:{
                    username: [
                        {required: true, validator: validateUsername, trigger: 'blur'},
                    ],
                    password: [
                        {required: true, validator: validatePass, trigger: 'blur'},
                    ],
                    password_confirm: [
                        {required: true, validator: validatePass_confirm, trigger: 'blur'},
                    ],
                }
            }
        },
        methods:{
            submitRegister: function () {
                const me = this;
                me.$refs["regiForm"].validate((valid) => {
                    if (valid) {

                        oucy.postRequest('/client/user/userauth/upInfoSetup', {
                            username:me.regiForm.username,
                            password:me.regiForm.password,
                            password_confirm:me.regiForm.password_confirm
                        }).then(res=>{
                            return  userMaterial.queryUserMaterial();
                        }).then(res=>{
                            me.$emit(oucy.msgKeys.UPDATE_LOGIN_USER_INFO);
                            this.$oucy.back()
                            location.reload();
                            oucy.replace('/', function () {
                                me.$emit(oucy.msgKeys.UPDATE_LOGIN_USER_INFO);
                                location.reload();
                            });
                        });

                    }
                });

            },
            cancelRegister: function () {
                this.$oucy.back()
                userMaterial.queryUserMaterial().then(res=>{
                    me.$emit(oucy.msgKeys.UPDATE_LOGIN_USER_INFO);
                })
                return
                const me = this;
                // this.$router.replace('/');
                oucy.replace('/', function () {
                    userMaterial.queryUserMaterial().then(res=>{
                        me.$emit(oucy.msgKeys.UPDATE_LOGIN_USER_INFO);
                    })
                });
            }
        }
    }
</script>

<style scoped>
.accComplete{
    text-align: left;
    width: 100%;
    min-height: 600px;

    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.form-container{
    width: 400px;
}
</style>
